import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home/Hero";
import Company from "../sections/home/Company";
import Features from "../sections/home/Features";
import CTA from "../sections/home/CTA";
import Content from "../sections/home/Content";
import Testimonial from '../sections/home/Testimonial';

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          footerStyle: 'style4'
        }}
      >
        <Hero className="position-relative z-index-1" />
        <Features className="bg-default-1 pt-lg-13 pb-md-6 pb-lg-6 position-relative" />
        <Content />
        <Testimonial className="pt-lg-13 pb-10 pb-lg-12" />
        <Company className="bg-default-1 pt-13 pt-md-17 pt-lg-14 pb-13 pb-md-14 pb-lg-10" />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
