import React from "react";
import FeatureCard from "../../components/FeatureCard";
import liveVirtual from '../../assets/image/svg/icons/live-virtual.svg';
import classroom from '../../assets/image/svg/icons/classroom.svg';
import workplace from '../../assets/image/svg/icons/workplace.svg';

const items = [
  {
    icon: liveVirtual,
    title: 'Live & Virtual',
    content:
      'You receive the same comprehensive live training from Ocorie experts without the need to travel by using seamless over-the Web connectivity.'
  },
  {
    icon: classroom,
    title: 'Classroom Style',
    content:
      'We run small-sized classes at our locations where students have face-to-face interactions with our tutors.'
  },
  {
    icon: workplace,
    title: 'Workplace Training',
    content: 'We offer diversified training programmes for companies and organisations at their workplaces.'
  }
];

const Features = () => {
  return (
    <div id="training-methods" className="pt-lg-24">
      <div className="container">
        {/* section title */}
        <div className="col-xl-5 col-lg-6 col-md-7 mb-7 mb-lg-9">
          <div
            className="mb-7 mb-lg-17 text-center text-md-left"
            data-aos="fade-right"
            data-aos-duration="200"
            data-aos-delay="100"
            data-aos-once="true"
          >
            <h2 className="font-size-10 pr-md-10 pr-xl-0 mb-0">
              Our
              <br className="d-none d-md-block"></br> training methods
            </h2>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-4 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={300}
              key={index}
            >
              <FeatureCard options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
