import React from "react";

import cima from '../../assets/image/partner-logos/cima-logo.png';
import innovare from '../../assets/image/partner-logos/innovare-logo.png';
import nanimator from '../../assets/image/partner-logos/naninator_logo.png';
import infoWatch from '../../assets/image/partner-logos/info-watch-logo.png';
import offensiveShield from '../../assets/image/partner-logos/offensive-shield-logo.png';
import secureWorks from '../../assets/image/partner-logos/sw-logo-black.png';

const SingleBrand = ({image}) => {
  return (
    <div className="mx-5 my-6">
      <img
        className="single-brand-logo"
        src={image}
        alt=""
        data-aos="fade-in"
        data-aos-once="true"
      />
    </div>
  );
}

const Company = ({ className, ...rest }) => {
    const images = [cima, innovare, secureWorks, nanimator, infoWatch];
    const images2 = [
      offensiveShield,
    ];
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mb-lg-8 text-center text-lg-center">
              <h5 className="font-size-7 font-weight-normal">
                Our Strategic Partners
              </h5>
              <p className="font-size-5 font-weight-normal text-bali-gray">
                Our courses are built in partnership with global technology giants and are relevant to current industry needs.
              </p>
            </div>
          </div>
        </div>
        {/* Brand Logos */}
        <div className="brand-logo-small d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap">
          {images.map((brand, index) => (
            <SingleBrand image={brand} key={index} />
          ))}
        </div>
        <div className="pt-md-2 brand-logo-small d-flex align-items-center justify-content-center flex-wrap">
          {images2.map((brand, index) => (
            <SingleBrand image={brand} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Company;
